import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";

const Shop = () => {
  return (
    <Root>
      <SEO title="Shop" />
      <h1>Shop</h1>
    </Root>
  );
};
export default Shop;
